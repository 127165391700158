<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-form :model="form" inline>
        <el-form-item :label="$t('ding-dan-zhuang-tai')" prop="logisticsStatus">
          <el-select v-model="form.logisticsStatus">
            <el-option
              v-for="(item, i) in statusList"
              :key="i"
              :value="item.value"
              ::label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('shen-qing-ri-qi-0')" prop="orderTime">
          <el-date-picker
            v-model="form.orderTime"
            type="daterange"
            :start-placeholder="$t('kai-shi-ri-qi')"
            :end-placeholder="$t('jie-shu-ri-qi')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">{{ $t('cha-xun') }}</el-button>
          <el-button>{{ $t('zhong-zhi') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-container">
          <el-table :data="tableData" border>
            <el-table-column
              align="center"
              :label="$t('ding-dan-hao-0')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('shen-qing-ri-qi-1')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('tui-kuan-li-you')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('tui-kuan-shuo-ming')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('shang-pin-jin-e')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('zhuang-tai-0')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column align="center" :label="$t('cao-zuo')" width="160px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="orderDetail(scope.row)"
                  >{{ $t('cha-kan') }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      title="$t('tui-kuan-xiang-qing')"
      :visible.sync="detailDialog"
      width="600px"
      class="order-detail-dialog"
    >
      <div class="top-info">
        <div class="title">{{ $t('ding-dan-xiang-qing') }}</div>
        <div class="info-line">
          <div class="label">{{ $t('shen-qing-shi-jian') }}</div>
          <div class="value">1111</div>
        </div>
        <div class="info-line">
          <div class="label">{{ $t('tui-kuan-dan-hao') }}</div>
          <div class="value">1111</div>
        </div>
        <div class="info-line">
          <div class="label">{{ $t('tui-kuan-jin-e') }}</div>
          <div class="value">1111</div>
        </div>
        <div class="info-line">
          <div class="label">{{ $t('tui-kuan-zhuang-tai') }}</div>
          <div class="value">1111</div>
        </div>
        <div class="info-line">
          <div class="label">{{ $t('tui-kuan-shuo-ming-0') }}</div>
          <div class="value">1111</div>
        </div>
        <div class="info-line">
          <div class="label">{{ $t('tui-kuan-li-you-0') }}</div>
          <div class="value">1111</div>
        </div>
      </div>
      <div class="bottom-info border">
        <div class="title">{{ $t('tui-kuan-chan-pin') }}</div>
        <el-table :data="tableData" border size="mini">
          <el-table-column
            align="center"
            width="120px"
            :label="$t('feng-mian-tu')"
            prop="orderNumber"
          ></el-table-column>
          <el-table-column
            align="center"
            width="120px"
            :label="$t('chan-pin-ming-cheng')"
            prop="orderNumber"
          ></el-table-column>
          <el-table-column
            align="center"
            :label="$t('gui-ge-0')"
            prop="orderNumber"
          ></el-table-column>
          <el-table-column
            align="center"
            :label="$t('shu-liang')"
            prop="orderNumber"
          ></el-table-column>
          <el-table-column
            align="center"
            :label="$t('dan-jia')"
            prop="orderNumber"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabList: [
        this.$t('quan-bu-ding-dan'),
        this.$t('dai-cai-gou'),
        this.$t('yi-cai-gou')
      ],
      tabIndex: 0,
      form: {},
      tableData: [{ orderNumber: 111 }],
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 123
      },
      statusList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: 1,
          label: this.$t('shen-qing-zhong')
        },
        {
          value: 2,
          label: this.$t('cheng-gong')
        },
        {
          value: 3,
          label: this.$t('shi-bai')
        }
      ],
      detailData: {},
      logisticsList: [{}, {}, {}, {}],
      detailDialog: false
    }
  },
  methods: {
    changeTab(i) {
      this.tabIndex = i
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {},
    currentChange(page) {},
    orderDetail(data) {
      this.detailData = data
      this.detailDialog = true
    }
  }
}
</script>